export default [
  {
    title: 'CRM',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Novo cliente',
        route: { name: 'crm-clients-new', params: {} },
        resource: 'crm',
        action: 'new',
      },
      {
        title: 'Clientes',
        route: { name: 'crm-clients-list', params: {} },
        resource: 'crm',
        action: 'list',
      },
    ],
  },
]
