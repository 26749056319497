export default [
  {
    title: 'Encomendas',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Listagem',
        route: { name: 'sales-orders-list', params: {} },
        resource: 'sales',
        action: 'list',
      },
      {
        title: 'Nova encomenda',
        route: { name: 'sales-orders-new', params: {} },
        resource: 'sales',
        action: 'new',
      },
    ],
  },
]
